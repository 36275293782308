import { Link } from "react-router-dom";

const JobsProjectCoordinator = () => {
    return (
    <>

    <div class="breadcrumb">
        <span class="caret">&gt;</span>
        <span class="link"><Link to="/jobs">Work Opportunities</Link></span>
        <span class="caret">&gt;</span>
        <span class="current">Mid-Level Project Coordinator</span>
    </div>

    <h1>Project Coordinator</h1>

    <div class="wpb_wrapper">
    <strong><font color="#2272FF">Job Overview:</font></strong> Saigon A.I. is seeking a talented and motivated Mid-Level Project Coordinator with 5 years of professional experience, particularly with development teams, who is looking to build a long-term career in a dynamic and innovative tech environment.

 <p></p>
 <strong><font color="#2272FF">Key Responsibilities:</font></strong><p></p>
    <ul>
        <li>Assist the Project Manager in planning, tracking, and coordinating project activities</li>
        <li>Maintain and update project management software and documentation</li>
        <li>Coordinate communication between development teams, stakeholders, and international customers</li>
        <li>Support project scheduling, resource allocation, and progress tracking</li>
        <li>Prepare and distribute project reports and status updates</li>
        <li>Help manage project budgets and monitor project expenses</li>
        <li>Facilitate meetings and take detailed meeting minutes</li>
        <li>Support team collaboration and ensure efficient workflow</li>
        <li>Assist in risk identification and mitigation strategies</li>
        <li>Support onboarding and training of new team members</li>
    </ul>
<p></p>
<strong><font color="#2272FF">Qualifications:</font></strong><p></p>
    <ul>
        <li>Bachelor's degree in Business Administration, Project Management, or related field</li>
        <li>5 years of experience in project coordination or related roles</li>
        <li>Proven organizational skills with exceptional attention to detail</li>
        <li>Excellent communication skills in both English and Vietnamese</li>
        <li>Proficiency in project management software (e.g., Jira, Trello, Asana)</li>
        <li>Strong interpersonal and teamwork abilities</li>
        <li>Ability to work effectively in a fast-paced, international business environment</li>
    </ul>

 <p></p>
 <strong><font color="#2272FF">Preferred Qualifications:</font></strong><p></p>
    <ul>
        <li>Experience with Agile methodology</li>
        <li>Previous experience working with international teams and customers</li>
        <li>Basic understanding of software development processes</li>
        <li>Familiarity with IT and AI industry terminology</li>
    </ul>

 <p></p>
 <strong><font color="#2272FF">Compensation and Benefits:</font></strong><p></p>
    <ul>
        <li>Salary will be discussed based on candidates' experience and ability. 100% salary during probation.</li>
        <li>Collaborative and innovative work environment.</li>
        <li>Opportunity for growth and training. </li>
        <li>Opportunities to work with global customers from different cultures.</li>
        <li>Experience an international business environment, which provides opportunities to improve Business English.</li>
        <li>Company Retreat once a year for full-time employees.</li>
        <li>Experience the unique harmony of Vietnamese - American Culture.</li>
        <li>Tea-times, Coffee Talk, I.T. English Club, sports activities, in-house games, etc.</li>
        <li>Working time: Monday - Friday, 8:30AM - 6:00PM.</li>
        <li>Annual Salary review and performance bonus.</li>
        <li>12 days annual leave per year.</li>
        <li>Company-provided MacOS computer, iPad, camera, and earbuds for working at the office.</li>
        <li>Insurance provided in full compliance with the Vietnam Labor Code.</li>
    </ul>

<div class="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
<span class="vc_sep_holder vc_sep_holder_l">
    <span class="vc_sep_line"></span>
</span>
<span class="vc_sep_holder vc_sep_holder_r">
    <span class="vc_sep_line"></span>
</span>
</div>
</div>

<Link to="/jobs/process" class="btn btn-warning">How To Apply</Link>


    </>
    )
  };
  
  export default JobsProjectCoordinator;