
import { Seo } from '../layouts/Seo';
import { Card, CardBody, CardHeader } from "react-bootstrap";
import HubspotForm from 'react-hubspot-form'

import "./Workshop.css";


const Page = () => {
    return (
    <>


  <Seo
        title="About Saigon A.I. - Professional Workshops"
        description="2025 hands on workshops for business professionals"
        type="webapp"
      />

<div class="breadcrumb">
    <span class="caret">&gt;</span>
    <span class="current">Workshops</span>
</div>

<h2 class="featurette-heading">2025 Professional Workshops: <span class="text-muted">Hands On.</span></h2>
<br/>

<div class="row featurette">
  <div class="col-lg-12">

    <p><strong>"Cutting Through The Noise" Events 2025</strong></p>

    <p class="lead">
        <p>Saigon A.I. is running a series of professional AI workshops, designed for industry professionals, executives and managers in any industry.  
            We have a reputation of "cutting through the noise" and avoiding hype, while helping with practical frameworks and tips to help you advance your organization.
        </p> 
    </p>

    <p><strong>Upcoming Events</strong></p>

    <Card> 
        <CardHeader><strong>27 February 2025: Organization Readiness for AI</strong></CardHeader>   
        <CardBody>
          <div class="img-cropper-250">
          <img src="/img/workshops/2025/feb-27-ai-readiness-teaser.png" 
            class="d-block mx-lg-auto img-fluid img-workshop" 
            alt="Saigon A.I. February Workshop - AI readiness" width="100%" loading="lazy" />
        </div>

        <p>Stop wondering if you are AI-ready. Know for sure - and know exactly what to do next.</p>
        <p>Transform uncertainty into ACTION at Saigon A.I.'s February professional AI workshop. </p>
        <p>Không còn mơ hồ về AI - Nắm bắt thực trạng, định hình tương lai </p>
        <p>Did you know that 70-80% of AI projects fail? But with the right preparation, yours doesn't have to. 
          We're hosting a hands-on workshop designed specifically for business leaders like you to assess AI readiness and develop a practical, actionable implementation plan.</p>
        <p>This workshop is perfect if you're:
        <ul>
          <li>Wondering how to start your AI journey</li>
          <li>Looking to avoid common implementation pitfalls</li>
          <li>Ready to move beyond the hype and achieve real results</li>
        </ul></p>
<p><strong>Workshop Details:</strong>
<blockquote>Topic: Organization Readiness for AI  <br />
Date/Time: February 27, 2025  9:00am - 12:00pm  <br />
Location: 259 &#272;. Tr&#7849;n H&#432;ng &#272;&#7841;o, P. C&#244; Giang, Qu&#7853;n 1, H&#7893; Chí&#237; Minh   <br />
Fee: 500,000 VND
</blockquote></p>
<p><strong>Featured Experts</strong>:
        <ul>
          <li><strong>Mr. Andrew Schwabe</strong><br/> 
          <a href="https://linkedin.com/in/aschwabe" class="dark">Founder & Chairman at ForMotiv, Co-Founder & Chairman at Saigon A.I.</a><br />
          <p class="p-small">
          Andrew is a serial entrepreneur and an A.I. researcher at University of St. Andrews, Scotland with more than 25 years experience building international technology companies.  An innovator at heart, Andrew is a forward-thinker and a problem solver, and has worked with cloud, analytics, Artificial Intelligence and blockchain technologies.
          </p></li>
          <li><strong>Ms. Rebecca Joy</strong><br/>
          <a href="https://linkedin.com/in/joyrebecca" class="dark">CEO, Co-Founder at Saigon A.I.</a><br />
          <p class="p-small">A seasoned technology entrepreneur with a career spanning over 25 years, Rebecca has co-founded six startups. Her extensive expertise encompasses accounting, statistics, analytics, blockchain, and data science, with successful projects in FinTech, InsurTech, Logistics, and beyond. Educated at UC Berkeley, Rebecca is a leader and advocate for bringing young women into technology.</p>
          </li>
        </ul></p>
<p>You'll walk away with:
        <ul>
          <li>A customized AI readiness assessment</li>
          <li>Practical implementation strategies</li>
          <li>Direct access to industry experts</li>
          <li>Valuable connections with fellow innovators</li>
        </ul></p>
<p>Ready to join us? <br />
Space is limited, so register today to guarantee your spot
</p>
        </CardBody>
    </Card>

    <br></br>    

    <p><strong>Register</strong></p>
    <HubspotForm
        portalId='8948467'
        formId='cb685401-3cce-4266-9507-137054d79124'
        onSubmit={() => console.log('Submit!')}
        onReady={(form) => console.log('Form ready!')}
        loading={<div>Loading...</div>}
    />

  </div>
</div>  

    </>
    )
  };
  
  export default Page;