import { Link } from "react-router-dom";

const JobsSalesIntern = () => {
    return (
    <>

    <div class="breadcrumb">
        <span class="caret">&gt;</span>
        <span class="link"><Link to="/jobs">Work Opportunities</Link></span>
        <span class="caret">&gt;</span>
        <span class="current">Sales Intern</span>
    </div>

    <h1>Sales Intern</h1>

    <div class="wpb_wrapper">

    <strong><font color="#2272FF">Job Description:</font></strong> 
<p></p>
We are looking for a highly motivated Sales Intern to join our team. This internship is a fantastic opportunity for someone looking to begin a career in tech sales, particularly in the rapidly growing AI sector. You’ll support the sales team, interact with prospective clients, and gain firsthand experience in driving business development efforts.
<p></p>
<strong><font color="#2272FF">Key Responsibilities:</font></strong>
<p></p>
    <ul>
        <li>Come ready to learn.</li>
        <li>Conduct research to identify potential clients and gather information on target industries, market trends, and competitors.</li>
        <li>Assist with sourcing, qualifying, and tracking leads in our CRM, ensuring an organized and efficient sales pipeline.</li>
        <li>Support outreach efforts through email campaigns, phone calls, and LinkedIn messages to connect with prospective clients.</li>
        <li>Collaborate with the sales team to prepare presentations and proposals for potential clients.</li>
        <li>Analyze sales data to gain insights, assist with sales strategies, and make data-driven recommendations.</li>
        <li>Assist in the planning and coordination of client meetings, product demos, and networking events.</li>
        <li>Prepare weekly reports on lead generation, outreach results, and project status.</li>
    </ul>
    <p></p>
<strong><font color="#2272FF">Qualifications:</font></strong>
<p></p>
    <ul>
        <li>Currently pursuing or recently completed a degree in Business, Marketing, Economics, or a related field.</li>
        <li>Strong written and verbal communication skills in English.</li>
        <li>A self-starter who takes initiative and demonstrates curiosity about tech sales and AI.</li>
        <li>Basic knowledge of CRM tools, Microsoft Office, and G-Suite.</li>
        <li>Ability to work independently and as part of a team.</li>
        <li>Ability to interpret data and apply it effectively in a sales context.</li>
        <li>Ability to handle multiple tasks, prioritize, and meet deadlines.</li>
    </ul>
<p></p>
<strong><font color="#2272FF">A Plus:</font></strong>
<p></p>
    <ul>
        <li>Familiarity with AI or machine learning concepts.</li>
        <li>Passionate about technology, AI, and staying up-to-date with industry trends.</li>
    </ul>
<p></p>    
<strong><font color="#2272FF">What We Offer:</font></strong>
<p></p>
    <ul>
        <li>Hands-on experience in a fast-growing tech company.</li>
        <li>Mentorship and professional development opportunities.</li>
        <li>A supportive work environment.</li>
    </ul>

<div class="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
<span class="vc_sep_holder vc_sep_holder_l">
    <span class="vc_sep_line"></span>
</span>
<span class="vc_sep_holder vc_sep_holder_r">
    <span class="vc_sep_line"></span>
</span>
</div>
</div>

<Link to="/jobs/process" class="btn btn-warning">How To Apply</Link>


    </>
    )
  };
  export default JobsSalesIntern;