import React from "react";
import { Link } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';

import "./Home.css";
import { Seo } from '../layouts/Seo';

function redirectHash(hash) {
  console.log(hash)
  if (hash === "press") {
    window.location.href = '/press'
  }
  else if (hash === "capabilities") {
    window.location.href = '/capabilities'
  }
  else if (hash === "contact") {
    window.location.href = '/contact'
  }
}

const Home = () => {

  const path = window.location.href;
  const hash =
  path.indexOf("#") !== -1 ? path.slice(path.indexOf("#") + 1) : "";
  if (hash) {
    redirectHash(hash);
  }

  return (
    <>

    <Seo
        title="Saigon A.I. - An Award Winning A.I. Advisory Company"
        description="We offer A.I. design and consulting services, including data science, automation, model design and ops; We are your trusted guide to navigate the ever changing A.I. landscape. We help you advance your data driven projects by supporting your team or by leading projects remotely."
        type="webapp"
      />

<div class="container col-xxl-12 px-4" >
  <div class="row flex-lg-row-reverse align-items-center g-4">
    <div class="col-12">
      <a href="/events/workshop"><img src="/img/workshops/2025/feb-27-ai-readiness-teaser.png" class="d-block mx-lg-auto img-fluid roundy20" alt="Saigon A.I. February Workshop - AI readiness" width="90%" loading="lazy" /></a>
    </div>
  </div>
</div>

{/* <div class="container col-xxl-12 px-4" >
  <div class="row flex-lg-row-reverse align-items-center g-4 py-3">
    <div class="col-10 col-sm-8 col-lg-6">
      <img src="/img/home_logos.png" class="d-block mx-lg-auto img-fluid roundy20" alt="Saigon A.I. Partner Logos" width="500" height="400" loading="lazy" />
    </div>
    <div class="col-lg-6">
      <h1 class="display-5 fw-bold lh-1 mb-3">Award Winning A.I. Advisory Company</h1>
      <p class="lead">We help our customers design, build and support their data and A.I. products and offerings. We offer <Link to="/solutions">data science, automation, model design and ops solutions</Link> to help you accelerate.</p>
      <div class="d-grid gap-2 d-md-flex justify-content-md-start">
        <Link to="/about/process" type="button" href="#process" onclick="load_page(this)" data-content="content/process" class="btn btn-primary px-4 me-md-2">Learn How We Boost Your Innovation</Link>
      </div>
    </div>
  </div>
</div> */}

<Carousel>
    <Carousel.Item>
        <Carousel.Caption>
          <h3>Transform Your Business with AI Automation</h3>
          <p>
          Is your organization actively working toward personal efficiency with A.I. tools for your workforce?
          &nbsp;
          <Link to="/solutions/automation" class="btn btn-xs btn-warning pull-right">Read More</Link>
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    <Carousel.Item>
        <Carousel.Caption>
          <h3>A.I. First Mindset</h3>
          <p>
          We specialize in guiding businesses—whether you're in logistics, finance, or e-commerce—through every step of the AI adoption process.
          &nbsp;
          <Link to="/solutions/automation" class="btn btn-xs btn-warning pull-right">Read More</Link>
          </p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <Carousel.Caption>
          <h3>Dashboarding</h3>
          <p>
          Deliver insights with story telling power, using leading tech like Tableau, Grafana, Power BI, Jupyter, Quicksight and others.
          &nbsp;
          <Link to="/capabilities/dashboard" class="btn btn-xs btn-warning pull-right">Read More</Link>
          </p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <Carousel.Caption>
          <h3>Data Engineering</h3>
          <p>
          We can help design your data and deployment models to minimize costs and drive real-time value.
          &nbsp;
          <Link to="/capabilities/dataengineering" class="btn btn-xs btn-warning pull-right">Read More</Link>
          </p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <Carousel.Caption>
          <h3>Data Process Auditing</h3>
          <p>We work with your team to monitor and validate data processes and model lifecycles, offering security, perspective and insights to empower growth.
          &nbsp;
          <Link to="/capabilities/dataaudit" class="btn btn-xs btn-warning pull-right">Read More</Link>
          </p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <Carousel.Caption>
          <h3>Language Models</h3>
          <p>Product integration, development and lifecycle management, we can help you prototype, fine-tune or design your own Large Language Models (LLM) or focused Small Language Model.
          &nbsp;
          <Link to="/capabilities/language_models" class="btn btn-xs btn-warning pull-right">Read More</Link>
          </p>
        </Carousel.Caption>
      </Carousel.Item>

</Carousel>



    </>
    )
  };
  
  export default Home;