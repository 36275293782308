import { React, StrictMode} from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';

import Layout from "./layouts/Layout";
import Home from "./pages/Home";
import About from "./pages/About"
import Press from "./pages/Press"
import Process from "./pages/Process"
import Capabilities from "./pages/Capabilities"
import Culture from "./pages/Culture"
import Story from "./pages/Story"
import Learn from "./pages/Learn"
import Jobs from "./pages/Jobs"
import JobsApplicationProcess from './pages/JobsProcess'
import JobsDataAnalystIntern from "./pages/JobDataAnalystIntern"
import JobsPythonCodeReviewer from "./pages/JobPythonCodeReviewer"
import JobsDSProjectCoordinator from "./pages/JobDSProjectCoordinator"
import JobsProjectCoordinator from "./pages/JobProjectCoordinator"
import JobsDataAnalyst from "./pages/JobDataAnalyst"
import JobsMarketingIntern from "./pages/JobMarketingIntern"
import JobsSalesIntern from "./pages/JobSalesIntern"
import JobsKeyThings from "./pages/JobKeyThings"
import JobsBizDev from "./pages/JobBizDev"
import JobsDevIntern from "./pages/JobDevIntern"
import Contact from "./pages/Contact"
import Dashboard from "./pages/Dashboard"
import DataAudit from "./pages/DataAudit"
import LanguageModels from './pages/LanguageModels';
import DeepLearning from "./pages/DeepLearning"
import NoPage from "./pages/NoPage";
import NaturalLanguage from './pages/NaturalLanguage';
import DataAnalysis from './pages/DataAnalysis';
import DataEngineering from './pages/DataEngineering';
import ExplainableAI from './pages/ExplainableAI';
import Leadership from './pages/Leadership';
import AIMLModeling from './pages/AIMLModeling';
import Behavioral from './pages/Behavioral';
import GenerativeAI from './pages/GenerativeAI';
import Solutions from "./pages/Solutions";
import AIFirst from './pages/AIFirst';
import Automation from './pages/Automation';
import Workshop from './pages/Workshop';

export default function App() {

  return (
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/about/process" element={<Process />} />
          <Route path="/about/culture" element={<Culture />} />
          <Route path="/about/story" element={<Story />} />
          <Route path="/about/leadership" element={<Leadership />} />
          <Route path="/solutions" element={<Solutions />} />
          <Route path="/solutions/aifirst" element={<AIFirst />} />
          <Route path="/capabilities" element={<Capabilities />} />
          <Route path="/capabilities/dashboard" element={<Dashboard />} />
          <Route path="/capabilities/dataaudit" element={<DataAudit />} />
          <Route path="/capabilities/language_models" element={<LanguageModels />} />
          <Route path="/capabilities/deep_learning" element={<DeepLearning />} />
          <Route path="/capabilities/nlp" element={<NaturalLanguage />} />
          <Route path="/capabilities/data_analysis" element={<DataAnalysis />} />
          <Route path="/capabilities/dataengineering" element={<DataEngineering />} />
          <Route path="/capabilities/explainable_ai" element={<ExplainableAI />} />
          <Route path="/capabilities/ai_ml_modeling" element={<AIMLModeling />} />
          <Route path="/capabilities/behavioral" element={<Behavioral />} />
          <Route path="/capabilities/generative_ai" element={<GenerativeAI />} />
          <Route path="/solutions/automation" element={<Automation />} />

          <Route path="/learn" element={<Learn />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/jobs/process" element={<JobsApplicationProcess />} />
          <Route path="/jobs/ds_intern" element={<JobsDataAnalystIntern />} />
          <Route path="/jobs/python_reviewer" element={<JobsPythonCodeReviewer />} />
          <Route path="/jobs/ds_coordinator" element={<JobsDSProjectCoordinator />} />
          <Route path="/jobs/project_coordinator" element={<JobsProjectCoordinator />} />
          <Route path="/jobs/data_analyst" element={<JobsDataAnalyst />} />          
          <Route path="/jobs/marketing_intern" element={<JobsMarketingIntern />} />
          <Route path="/jobs/sales_intern" element={<JobsSalesIntern />} />          
          <Route path="/jobs/dev_intern" element={<JobsDevIntern />} />
          <Route path="/jobs/biz_dev" element={<JobsBizDev />} />   
          <Route path="/jobs/key_things" element={<JobsKeyThings />} />          

          <Route path="/events/workshop" element={<Workshop />} />          

          <Route path="/press" element={<Press />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
const helmetContext = {};

root.render(
  <StrictMode>
    <HelmetProvider context={helmetContext}>
      <App />
    </HelmetProvider>
  </StrictMode>,
  document.getElementById('root')
);

